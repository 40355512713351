import { Center, useColorModeValue } from "@chakra-ui/react"
import React from "react"
const RooftopLogo = () => {
  const color = useColorModeValue('black', 'white')
  return (
    <Center>
      <svg version="1.1" id="logo-rooftop-svg" xmlns="http://www.w3.org/2000/svg" fill={color} width="100px" style={{ marginBotton: 0 }}>
        <polygon points="0.1,76.5 44.2,49.5 88.4,76.5 44.2,63.3" />
      </svg>
    </Center>
  )
}
export default RooftopLogo