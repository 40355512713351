import React, { useEffect, useState } from 'react';
import { Center, Container, Input, Text, InputGroup, Stack, Tooltip, useColorMode, useToast, Flex, Box, IconButton, InputRightElement, Fade, Heading } from '@chakra-ui/react'
import { MoonIcon, RepeatIcon, SunIcon } from '@chakra-ui/icons'
import converter from './utils/converter';
import RooftopLogo from './components/RooftopLogo';
import MacHelp from './components/MacHelp';
import getTip from './utils/getTip';
const App = () => {
  const [input, setInput] = useState('')
  const [output, setOutput] = useState('')
  const [tip, setTip] = useState()
  const { colorMode, toggleColorMode } = useColorMode()
  const toast = useToast()

  useEffect(() => {
    setTip(getTip())
  }, [])

  useEffect(() => {
    setOutput(converter(input))
  }, [input])

  const notifyCopied = () => {
    toast({
      title: "Copied to clipboard!",
      variant: "solid",
      isClosable: true,
      duration: 2000
    })
  }

  const handleOutput = (e) => {
    if (output === '') return
    e.target.select()
    if (!navigator.clipboard) {
      document.execCommand('copy')
    } else {
      navigator.clipboard.writeText(output)
        .then(() => {
          notifyCopied()
        })
        .catch(() => {
          //If copy error, user should just copy manually
          console.error('Failed to copy to clipboard!')
        })
    }

  }

  return (
    <Flex justify='center' height='100vh'>
      <Box position="absolute" top="5" right="5">
        <IconButton onClick={toggleColorMode} icon={(colorMode === 'dark') ? <SunIcon /> : <MoonIcon />} />
      </Box>
      <Center>
        <Container minWidth="70vw">
          <Stack>
            <RooftopLogo />
            <Heading as='h2' align="center" paddingBottom="10px">Path converter &#10024;</Heading>
            <InputGroup>
              <Input value={input} placeholder='Put a X:\ or /Volumes/ here!' onChange={(e) => setInput(e.target.value)} />
              <InputRightElement>
                <Fade in={output !== ''}>
                  <IconButton onClick={() => {
                    setInput('')
                  }}
                    icon={<RepeatIcon />} />
                </Fade>
              </InputRightElement>
            </InputGroup>
            <Tooltip isDisabled={(output === '')} label='Click to copy!'>
              <InputGroup >
                <Input readOnly placeholder='Copy new path from here!' variant="filled" value={output} onClick={handleOutput} />
              </InputGroup>
            </Tooltip>
            <Center>
              <Text fontSize="s" marginTop={["0", "2rem"]}>
                <span style={{ opacity: .6 }}>TIP: </span>
                {tip}</Text>
            </Center>
            {(window.navigator.appVersion.indexOf('Mac') !== -1) &&
              <Center pt="20px">
                <MacHelp />
              </Center>}
          </Stack>
        </Container>
      </Center>
    </Flex >
  );
}

export default App
