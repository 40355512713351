const tips = [
  'You can use me offline!',
  'In Chrome, you can click install above to use this as an app!',
  `I don't need internet to work!`,
  'Click to copy!',
]

const getTip = () => {
  return tips[Math.floor(Math.random() * 3)]
}

export default getTip