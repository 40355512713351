const converter = (value) => {
  // Windows version:
  // X:\83.1 UNJSPF Pension Fund Series\2_Share Folder\1_Audio\VO\Actual\English

  // Mac version:
  // /Volumes/WIP/83.1 UNJSPF Pension Fund Series/2_Share Folder/1_Audio/VO/Actual/English
  let output = (value[0] === '/') ?
    `X:\\${value.split('/').slice(3).join('\\')}`
    : (value[2] === "\\")
      ? `/Volumes/WIP/${value.split('\\').slice(1).join('/')}`
      : ''
  return output
}

export default converter