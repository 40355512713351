import React from "react"
import { Kbd, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"
const MacHelp = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen}>Mac help</Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent paddingBottom="20px">
          <ModalHeader>
            How to copy path&#39;s with Finder on a Mac
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            On Mac, type <Kbd>command</Kbd> + <Kbd>Shift</Kbd> + <Kbd>g</Kbd> and paste
            <Text align="center" margin="10px 0 10px 0">or</Text>
            From the top menu, select <Kbd>Go</Kbd> and <Kbd>Go to Folder…</Kbd> and paste
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
export default MacHelp